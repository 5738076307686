.card-title.post-title {
  height: 100px;
}

.card-img-top.post-thumbnail {
  height: 150px;
}

.btn-link-grey {
  color: #333333;
  text-decoration: underline;
}

.btn-link-grey:hover {
  color: #000000;
  font-weight: 600;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 55px;
  width: 55px;
  background-size: 100%, 100%;
  border-radius: 50%;
  background-color: #444444;
}

.carousel-control-prev-icon {
  margin-left: -50px
}

.carousel-control-next-icon {
  margin-right: -50px
}

@media (min-width: 992px) {
  /* Show 4th slide on lg if col-lg-4*/
  .carousel-inner .active.col-lg-4.carousel-item + .carousel-item + .carousel-item + .carousel-item {
      position: absolute;
      top: 0;
      right: -33.3333%;  /*change this with javascript in the future*/
      z-index: -1;
      display: block;
      visibility: visible;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  /* Show 3rd slide on md if col-md-6*/
  .carousel-inner .active.col-md-6.carousel-item + .carousel-item + .carousel-item {
      position: absolute;
      top: 0;
      right: -50%;  /*change this with javascript in the future*/
      z-index: -1;
      display: block;
      visibility: visible;
  }
}

/* MD */
@media (min-width: 768px) {
  .carousel-item {
      margin-right: 0;
  }
  /* show 2 items */
  .carousel-inner .active + .carousel-item {
      display: block;
  }
  .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left),
  .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item {
      transition: none;
  }
  .carousel-inner .carousel-item-next {
      position: relative;
      transform: translate3d(0, 0, 0);
  }
  /* left or forward direction */
  .active.carousel-item-left + .carousel-item-next.carousel-item-left,
  .carousel-item-next.carousel-item-left + .carousel-item,
  .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item {
      position: relative;
      transform: translate3d(-100%, 0, 0);
      visibility: visible;
  }
  /* farthest right hidden item must be also positioned for animations */
  .carousel-inner .carousel-item-prev.carousel-item-right {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      display: block;
      visibility: visible;
  }
  /* right or prev direction */
  .active.carousel-item-right + .carousel-item-prev.carousel-item-right,
  .carousel-item-prev.carousel-item-right + .carousel-item,
  .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item {
      position: relative;
      transform: translate3d(100%, 0, 0);
      visibility: visible;
      display: block;
      visibility: visible;
  }
}

/* LG */
@media (min-width: 992px) {
  /* show 3rd of 3 item slide */
  .carousel-inner .active + .carousel-item + .carousel-item {
      display: block;
  }
  .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item {
      transition: none;
  }
  .carousel-inner .carousel-item-next {
      position: relative;
      transform: translate3d(0, 0, 0);
  }
  /* left or forward direction */
  .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item {
      position: relative;
      transform: translate3d(-100%, 0, 0);
      visibility: visible;
  }
  /* right or prev direction */
  .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item {
      position: relative;
      transform: translate3d(100%, 0, 0);
      visibility: visible;
      display: block;
      visibility: visible;
  }
}